import { EventEmitter, Injectable, inject } from '@angular/core';
import {
  AddPlaylistsToChannelsGQL,
  AssignChannelPlaylistRegionGQL,
  Channel,
  ChannelsForChannelListFragment,
  CreateChannelGQL,
  CreateChannelInput,
  Device,
  GetChannelGQL,
  GetChannelPlaylistsGQL,
  GetProfileChannelsForChannelListGQL,
  Maybe,
  Playlist,
  PlaylistStatus,
  PublishChannelsContentGQL,
} from '@designage/gql';
import { IPlaylistRegion } from '@desquare/interfaces';
import { Observable, lastValueFrom, map, tap } from 'rxjs';
import { CurrentUserService } from '../current-user/current-user.service';
import { GraphQLFormattedError } from 'graphql';

@Injectable({
  providedIn: 'root',
})
export class ChannelService {
  publishedPlaylistsForCurrentChannelChanged = new EventEmitter();
  assignChannelPlaylistRegionGQL = inject(AssignChannelPlaylistRegionGQL);

  constructor(
    private currentUserService: CurrentUserService,
    private createChannelGQL: CreateChannelGQL,
    private getChannelPlaylistsGQL: GetChannelPlaylistsGQL,
    private publishChannelsContentGql: PublishChannelsContentGQL,
    private addPlaylistsToChannelsGql: AddPlaylistsToChannelsGQL,
    private getChannelGQL: GetChannelGQL,
    private getProfileChannelsGql: GetProfileChannelsForChannelListGQL
  ) {}

  async getCurrentProfileChannels() {
    const profileId = this.currentUserService.getCurrentProfileId();
    return await this.getProfileChannelsList(profileId);
  }

  async getProfileChannelsList(profileId: string) {
    const { data } = await lastValueFrom(
      this.getProfileChannelsGql.fetch({ id: profileId })
    );
    return data.profile?.channels as Maybe<ChannelsForChannelListFragment>[];
  }

  channelsFromApi$ = inject(GetProfileChannelsForChannelListGQL).watch(
    {
      id: inject(CurrentUserService).getCurrentProfileId(),
    },
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    }
  );

  getChannelsFromApi$(): Observable<{
    channels: Channel[];
    loading: boolean;
    errors: readonly GraphQLFormattedError<Record<string, any>>[] | undefined;
  }> {
    return this.channelsFromApi$.valueChanges.pipe(
      map(({ data, errors, loading }) => {
        const channels: Channel[] = (data?.profile?.channels || []).map(
          (channel) => ({
            ...channel,
            devices: channel.devices.map((device) => ({
              ...device,
            })) as Device[],
            playlists: channel.playlists.map((playlist) => ({
              ...playlist,
            })) as Playlist[],
          })
        );
        return {
          channels,
          loading,
          errors,
        };
      })
    );
  }

  refreshChannels() {
    console.log('refreshing devices');
    // this.refreshApiData$.next();
    this.channelsFromApi$.refetch();
  }

  updateRegionGql(channeöId: string, input: IPlaylistRegion) {
    const { playlistId, region } = input;
    return lastValueFrom(
      this.assignChannelPlaylistRegionGQL.mutate({
        input: { channelId: channeöId, playlistId, region },
      })
    );
  }
  async getChannel(channelId: string) {
    const { data } = await lastValueFrom(
      this.getChannelGQL.fetch(
        { id: channelId },
        {
          fetchPolicy: 'network-only',
        }
      )
    );
    return data.channel;
  }

  async addPlaylistsToChannels(
    channelIds: string[],
    playlistIds: string[],
    region = '',
    publish = false
  ) {
    const { data } = await lastValueFrom(
      this.addPlaylistsToChannelsGql.mutate({
        input: {
          playlistIds,
          channelIds,
          region,
          publish,
        },
      })
    );
    return data?.addPlaylistsToChannels;
  }

  createChannel(input: CreateChannelInput) {
    return this.createChannelGQL.mutate(
      { input }
      /* not really usable here
      {
        refetchQueries: [
          {
            query: GetEventsListDocument,
            variables: {
              profileId: input.profileId,
              // deviceId: this.deviceId,
            },
          },
        ],
      }*/
    );
  }

  async getChannelPlaylists(
    channelId: string,
    statusFilters: PlaylistStatus[] = [PlaylistStatus.Active]
  ) {
    const { data } = await lastValueFrom(
      this.getChannelPlaylistsGQL.fetch(
        {
          channelId,
          playlistName: '',
          statuses: statusFilters,
        },
        { fetchPolicy: 'network-only' }
      )
    );
    return (data.channel?.playlists as Playlist[]) || [];
  }

  async republishChannelContent(channelIds: string[]) {
    const { data } = await lastValueFrom(
      this.publishChannelsContentGql.mutate({
        input: {
          channelIds,
        },
      })
    );

    return data?.publishChannels;
  }
}
